/* Masque complètement la date de base pour les jours spéciaux */
.special-date abbr {
  display: none; /* Supprime l'élément du flux */
}

/* Optionnel : si tu veux ajouter un style pour la case de la date spéciale */
.special-date {
  position: relative; /* Permet le positionnement absolu de l'élément enfant */
}

.selected-date {
  background-color: #add8e6; /* couleur bleu clair */
  color: white; /* couleur de texte blanche */
}